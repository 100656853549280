import React from 'react'
import { Helmet } from 'react-helmet/es/Helmet'
import styled from 'styled-components'
import Grid, { grid } from 'styled-components-grid'
import GridUnit from 'styled-components-grid/dist/cjs/components/GridUnit'
import BlogBanner from '../../components/blocks/BlogBanner'
import LegalPage from '../../components/blocks/LegalPage'
import Layout from '../../components/layouts/layout'

const Container = styled.div`
  ${grid({})};
  padding: 0 60px;
  margin-top: 40px;
  margin-bottom: 103px;
  position: relative;
  overflow: hidden;
  justify-content: center;
`

const TextContainer = styled(Container)`
  flex-direction: column;

  h4 {
    font-size: 12px;
    font-weight: 400;
    letter-spacing: 7.2px;
    line-height: 1.5;
    margin-bottom: 20px;
    text-transform: uppercase;
  }

  h5 {
    color: #000000;
    font-size: 32px;
    letter-spacing: 7px;
    padding-bottom: 22px;
    line-height: 1.2;
    overflow-wrap: break-word;
    font-weight: 700;
    text-align: center;
  }

  p {
    font-size: 13px;
    font-weight: 400;
    color: #a8a8a8;
    line-height: 1.661;
    letter-spacing: 0.26px;
    margin-bottom: 1rem;
  }
`

const TextPanel = styled(TextContainer)`
  p {
    text-align: center;
  }
`

const LegalPageTemplate = props => (
  <>
    <Helmet
      title='amber living - Stil & Eleganz für dein Zuhause | Impressum'
      meta={[
        {
          name: 'robots',
          content: props.pageContext.data?.index
            ? props.pageContext.data?.index
            : 'noindex, follow'
        }
      ]}
    />
    <Layout {...props} navigation={props.pageContext.navigation}>
      <LegalPage>
        <Container>
          <GridUnit size={{ xs: 1, lg: 7 / 16 }}>
            <BlogBanner.Box>
              <BlogBanner.BoxText>Impressum</BlogBanner.BoxText>
              <BlogBanner.BoxBottomText>AMBER LIVING</BlogBanner.BoxBottomText>
            </BlogBanner.Box>
          </GridUnit>
          <GridUnit size={{ sm: 1 / 16 }} />
          <GridUnit size={{ xs: 1, lg: 7 / 16 }} />
          <BlogBanner.FakeDiv />
        </Container>
        <TextContainer>
          <h4>Haftungsausschluss</h4>
          <p>
            Trotz sorgfältiger Kontrolle übernehmen wir keine Haftung für die
            Inhalte externer Links. Für die Inhalte der verlinkten Seiten sind
            ausschließlich die jeweiligen Betreiber verantwortlich.
          </p>
          <h4>Aktualität von Preisen</h4>
          <p>
            amber-living.de übernimmt keine Garantie für die Aktualität der auf
            dieser Internetseite angegebenen Preise. Maßgeblich sind die Preise
            der Partnershops, die über Verlinkungen erreicht werden können.
          </p>
          <h4>Externe Links</h4>
          <p>
            Diese Website enthält Verknüpfungen zu Websites Dritter ("externe
            Links"). Diese Websites unterliegen der Haftung der jeweiligen
            Betreiber. Der Anbieter hat bei der erstmaligen Verknüpfung der
            externen Links die fremden Inhalte daraufhin überprüft, ob etwaige
            Rechtsverstöße bestehen. Zu dem Zeitpunkt waren keine Rechtsverstöße
            ersichtlich. Der Anbieter hat keinerlei Einfluss auf die aktuelle
            und zukünftige Gestaltung und auf die Inhalte der verknüpften
            Seiten. Das Setzen von externen Links bedeutet nicht, dass sich der
            Anbieter die hinter dem Verweis oder Link liegenden Inhalte zu Eigen
            macht. Eine ständige Kontrolle der externen Links ist für den
            Anbieter ohne konkrete Hinweise auf Rechtsverstöße nicht zumutbar.
            Bei Kenntnis von Rechtsverstößen werden jedoch derartige externe
            Links unverzüglich gelöscht.
          </p>
          <h4>Urheber- und Leistungsschutzrechte</h4>
          <p>
            Die auf dieser Website veröffentlichten Inhalte unterliegen dem
            deutschen Urheber- und Leistungsschutzrecht. Jede vom deutschen
            Urheber- und Leistungsschutzrecht nicht zugelassene Verwertung
            bedarf der vorherigen schriftlichen Zustimmung des Anbieters oder
            jeweiligen Rechteinhabers. Dies gilt insbesondere für
            Vervielfältigung, Bearbeitung, Übersetzung, Einspeicherung,
            Verarbeitung bzw. Wiedergabe von Inhalten in Datenbanken oder
            anderen elektronischen Medien und Systemen. Inhalte und Rechte
            Dritter sind dabei als solche gekennzeichnet. Die unerlaubte
            Vervielfältigung oder Weitergabe einzelner Inhalte oder kompletter
            Seiten ist nicht gestattet und strafbar. Lediglich die Herstellung
            von Kopien und Downloads für den persönlichen, privaten und nicht
            kommerziellen Gebrauch ist erlaubt.
          </p>
          <p>Quelle: Disclaimer Muster von JuraForum.de.</p>
        </TextContainer>
        <Container>
          <Grid>
            <GridUnit size={{ sm: 1, md: 1, lg: 1 / 3 }}>
              <TextPanel>
                <h5>Stilvoll Wohnen</h5>
                <p>MIT AMBER LIVING</p>
              </TextPanel>
            </GridUnit>
            <GridUnit size={{ sm: 1, md: 1, lg: 2 / 3 }}>
              <img
                src='https://firebasestorage.googleapis.com/v0/b/amber-living-eu.appspot.com/o/images%2Fimpressum.jpg?alt=media'
                alt='impressum'
              />
            </GridUnit>
          </Grid>
        </Container>
      </LegalPage>
    </Layout>
  </>
)

export default LegalPageTemplate
